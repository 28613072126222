
.cards-list {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: auto;
  margin-right: auto;
  padding: 40px;
}

.card {
  width: 270px;
  /* height: 350px; */
  /* border: 1px solid; */
  border-style: groove;
}

img {
  width: 270px;
  height: 270px;
}

.box-product {
  text-align: left;
  height: 125px;
}

.product-brand {
  font-weight: 300;
}

.product-name {
  font-size: medium;
  font-weight: 400;
  line-height: 1.3;
  
}

.product-price {
  margin-top: 1.3px;
  font-weight: 440;
}

.card-question {
  /* width: 250px; */
  /* height: 250px; */
  margin-left: 25px;
  margin-bottom: 40px;
}

@media screen and (max-width: 660px) {
  .cards-list {
    padding: 17px;
    gap: 10px;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    width: 170px;
  }

  img {
    width: 168px;
    height: 180px;
  }

  .product-name {
    font-weight: 400;
  }

  .box-product {
    height: 160px;
  }
}

@media screen and (max-width: 415px) {
  .cards-list {
    padding: 17px;
    gap: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    width: 185px;
  }

  img {
    width: 182px;
    height: 190px;
  }

  .product-name {
    font-weight: 400;
  }

  .box-product {
    height: 160px;
  }
}

@media screen and (max-width: 412px) {
  .cards-list {
    padding: 17px;
    gap: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    width: 183px;
  }

  img {
    width: 180px;
    height: 190px;
  }

  .product-name {
    font-weight: 400;
  }

  .box-product {
    height: 160px;
  }
}

@media screen and (max-width: 391px) {
  .cards-list {
    padding: 17px;
    gap: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    width: 173px;
  }

  img {
    width: 170px;
    height: 180px;
  }

  .product-name {
    font-weight: 400;
  }

  .box-product {
    height: 160px;
  }
}


@media screen and (max-width: 376px) {
  .cards-list {
    padding: 17px;
    gap: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .card {
    width: 165px;
  }

  img {
    width: 162px;
    height: 172px;
  }

  .product-name {
    font-weight: 400;
  }

  .box-product {
    height: 180px;
  }
}
